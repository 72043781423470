import { environment as base } from "./environment.base";

export const environment = {
  ...base,
  code: 'verv',
  dashboard: '',
  ga: 'G-N2C5EYY59B',
  b2cPolicySignUp: 'B2C_1_MemberPortal_SignUp',
  b2cPolicySignIn: 'B2C_1_VERVStg_MemberPortal_SignUpSignIn',
  b2cSignInAuthority:'https://apexproductdevelopment.b2clogin.com/apexproductdevelopment.onmicrosoft.com/B2C_1_VERVStg_MemberPortal_SignUpSignIn',
  b2cSignUpAuthority:'https://apexsmrt.b2clogin.com/apexsmrt.onmicrosoft.com/B2C_1_MemberPortal_SignUp',
  b2cAuthorityDomain: 'apexproductdevelopment.b2clogin.com',
  b2cClientId: 'a24fe80c-adce-41bb-9a85-32e509cc9ef0',
  b2cReadScope: 'https://ApexProductDevelopment.onmicrosoft.com/48c8341e-cf28-415d-a001-b690bc6ba0b1/MemberPortal.User',
  b2cWriteScope: 'https://ApexProductDevelopment.onmicrosoft.com/48c8341e-cf28-415d-a001-b690bc6ba0b1/MemberPortal.User',
};
